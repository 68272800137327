import { StateCreator } from 'zustand'

export type MixState = {
  soundID: string;
  pronoun: string;
  quote: string;
  styleID: string;
}

export type MixActions = {
  setSoundID: (soundID: string) => void;
  setPronoun: (pronoun: string) => void;
  setQuote: (quote: string) => void;
  setStyle: (styleID: string) => void;
}

export type MixSlice = MixState & MixActions

export const createMixSlice: StateCreator<MixSlice, [], [], MixSlice> = (set) => ({
  soundID: '',
  pronoun: '',
  quote: '',
  styleID: '',
  setSoundID: (soundID: string) => set(() => ({ soundID })),
  setPronoun: (pronoun: string) => set(() => ({ pronoun })),
  setQuote: (quote: string) => set(() => ({ quote })),
  setStyle: (styleID: string) => set(() => ({ styleID })),
})
