import { create } from 'zustand'
import { persist } from 'zustand/middleware'
import { AuthSlice, createAuthSlice } from './auth'
import { createMixSlice, MixSlice } from './mix'

export type BoundStore = AuthSlice & MixSlice

export const createBoundStore = () => create<BoundStore, [['zustand/persist', Partial<BoundStore>]]>(
  persist(
    (...a) => ({
      ...createAuthSlice(...a),
      ...createMixSlice(...a)
    }),
    {
      name: 'bound-store',
      partialize: (state) => {
        return {
          phoneNumber: state.phoneNumber,
          token: state.token,
          refreshToken: state.refreshToken,
          expiredAt: state.expiredAt,
          sessionId: state.sessionId,
        }
      }
    }
  )
)