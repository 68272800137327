import { Button, Modal } from "flowbite-react";
import Icon from "../../components/Icon";
import useAuth from "../../hooks/useAuth";
import { useEffect } from "react";

let interval: number

export default function QRModal({open, handleClose, phoneNumber, code}: {
  open: boolean;
  handleClose: () => void;
  code: string;
	phoneNumber: string;
}) {
  const {sessionId, fetchSessionStatus, logout} = useAuth()
  useEffect(() => {
		(async () => {
			interval = setInterval(fetchSessionStatus, 5000)
			return () => {
				clearInterval(interval)
			}
		})()
	}, [sessionId, fetchSessionStatus])

  const handleCancel = () => {
    logout()
    clearInterval(interval)
    handleClose()
  }

  return (
    <Modal show={open} onClose={handleClose} popup>
      <Modal.Header />
      <Modal.Body>
        <div className="text-center">
          <h3 className="mb-8 text-xl font-bold text-black-500">
            Xác minh SĐT
          </h3>
          <span className="text-[15px] leading-[18.75px]">
            Hãy lựa chọn Android hay iOS theo dòng điện thoại của bạn và gửi tin nhắn để xác minh SĐT ngay nhé !
          </span>
          <div className="flex justify-center gap-4 mt-[36px]">
            <Button pill color="dark" onClick={() => window.open(`sms:${phoneNumber}?body=${code}`)}>
              <div className="flex gap-1 items-center justify-center">
                <Icon
                  name="android"
                  color={"#FFF"}
                />
                <span>Android</span>
              </div>
            </Button>
            <Button pill color="dark" onClick={() => window.open(`sms:${phoneNumber}&body=${code}`)}>
              <div className="flex gap-1 items-center justify-center">
                <Icon
                  name="apple"
                  color={"#FFF"}
                />
                <span>iOS</span>
              </div>
            </Button>
          </div>

          <div className="mt-4 mb-[24px] gap-4 flex flex-col">
            <div className="text-[15px] leading-[18.75px]">
              Vui lòng chờ 10s sau khi nhắn tin để xác thực.
            </div>
            <button
              onClick={handleCancel}
              className="text-red-500 underline"
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}