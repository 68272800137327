import { useQuery } from "@tanstack/react-query"
import useAuth from "./useAuth"
import { getPhoneChip } from "../services/auth"

export const usePhoneChip = () => {
  const {phoneNumber} = useAuth()

  const {data, isFetching, refetch} = useQuery({
    queryKey: ['phone-chip', phoneNumber],
    queryFn: async () => {
      if (!phoneNumber) return {}
      const rs = await getPhoneChip(phoneNumber)
      return rs.data.map((item: Record<string, any>) => {
        return {
          uuid: item.uuid,
          // name: item.style.name,
          // code: item.style.id,
          logo: item.image_url || '',
          credit: item.credit,
          phoneNumber: item.phone_number,
          totalClips: item.total_clips
        }
      }) || []
    }
  })

  return {
    data, isFetching, refetch,
    alreadyLinked: data && data.length > 0 ? true : false
  }
}