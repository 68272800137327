import { useState } from "react";
import { Drawer, Dropdown } from 'flowbite-react'
import Icon from "../Icon"
import useAuth from "../../hooks/useAuth";
import { Link } from "react-router-dom";
import { hidePhone } from "../../utils/string";
import Footer from "../Footer";
import { useTranslation } from "react-i18next";

export default function Header({showLogo = true, chipID, className = ''}: {
  className?: string;
  showLogo?: boolean;
  chipID?: string
}) {
  const {phoneNumber, logout, isLoggedIn} = useAuth()
  const [isOpen, setIsOpen] = useState(false);
  const handleClose = () => setIsOpen(false);

  const { t, i18n } = useTranslation<string>()

  const languageKeys = Object.keys(i18n.services.resourceStore.data)

  return (
    <div className={`h-[60px] p-[26px] flex flex-col items-center justify-center w-full ${className}`}>
      <div className="w-full flex justify-between">
        {/* <div className="w-[24px]"/> */}
        <div>
          {showLogo && (
            <img className="h-[42px]" src="/asset/logo.png" />
          )}
        </div>
        <div className="flex gap-2 items-center">
          {chipID && (
            <Link to={`/item/${chipID}`} className="text-[#141414] text-[14px] leading-[24px] uppercase font-bold">
              PLAYLIST
            </Link>
          )}
          <button onClick={() => setIsOpen(true)}>
            <Icon name="menu" />
          </button>
        </div>
      </div>
      <Drawer className="w-full backdrop-blur-md bg-white/50" open={isOpen} onClose={handleClose} position="right">
        {/* <Drawer.Header title="Drawer" className="text-white" /> */}
        <Drawer.Items className="h-full text-white">
          <div className="flex flex-col p-[16px] h-full">
            <div className="flex justify-between mb-[37px]">
              <img className="h-[60px]" src="/asset/logo.png" />
              <button onClick={handleClose}>
                <Icon name="close" color="#000000" width={14} height={14} />
              </button>
            </div>
            <div className="flex flex-1 flex-col">
              <div className="flex flex-col mb-[32px] gap-[16px]">
                {phoneNumber && phoneNumber !== '' ? (
                  <div className="flex gap-2">
                    <span className="text-[#595959] font-bold text-[18px] leading-[22.5px]">{hidePhone(phoneNumber)}</span>
                    <button className="text-black font-bold text-[18px] leading-[22.5px] uppercase" onClick={logout}>{t('logout')}</button>
                  </div>
                ) : (
                  <Link
                    className="uppercase font-bold text-[18px] leading-[22.5px]"
                    to="/login"
                  >
                    {t('login')}
                  </Link>
                )}
                {isLoggedIn && (
                  <Link
                    className="uppercase font-bold text-[16px] leading-[20px] text-black"
                    to="/profile"
                  >
                    {t('yourPlaylists')}
                  </Link>
                )}
              </div>
              <div className="flex pt-[100px]">
                <img src="/asset/header_star.png" className="h-[57px]" />
                <div className="flex flex-col gap-12">
                  <div className="flex flex-col gap-4 text-black text-[12px] leading-[15px]">
                    <div>{t('headerDesc1')}</div>
                    <div>{t('headerDesc2')}</div>
                  </div>
                  <div className="flex flex-col gap-4">
                    <div className="text-[#141414] text-[12px] leading-[15px] font-[500]">Welcome! Enter CONGVIENGAU</div>
                    <div className="flex gap-6">
                      <Icon name="instagram" color="#000000" />
                      <Icon name="twitter" color="#000000" />
                    </div>
                  </div>
                  <div className="text-black text-[12px] font-[500] leading-[15px]">
                    <Dropdown label={t(i18n.language)} inline size="sm">
                      {languageKeys.filter((i) => i !== i18n.language).map((lang: string) => {
                        return (
                          <Dropdown.Item
                            className="text-black text-[12px] font-[500] leading-[15px]"
                            onClick={() => i18n.changeLanguage(lang)}
                          >
                            {t(lang)}
                          </Dropdown.Item>
                        )
                      })}
                    </Dropdown>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="flex items-center justify-center">
              <button className="gap-4 flex" onClick={handleClose}>
                <div>[x]</div>
                <div>ĐÓNG</div>
              </button>
            </div> */}
            <Footer color="black" className="!px-0 pt-[56px]" />
          </div>
        </Drawer.Items>
      </Drawer>
    </div>
  )
}
