import { Link } from "react-router-dom";
import Icon from "../../components/Icon";
import { useItemDetail } from "../../hooks/useItemDetail";

export default function SongCard({imageURL, chipID}: {
  imageURL?: string;
  chipID?: string;
}) {

  const {item: chipDetail} = useItemDetail(chipID || '')

  return (
    <div
      className="h-[248px] shrink-0 w-[188px] rounded-[13px] bg-[#000000B2] backdrop-blur overflow-hidden">
      {imageURL
        ?
        <img
          src={imageURL}
          alt=""
          className='w-full h-full shadow-[0px_4px_24px_0px_#00000080]'
        />
        :
        <div
          className='w-full h-full p-5 font-oswald text-[#FCFCFC] flex flex-col justify-between'>
          <h4 className='text-[13px]'>Mỗi CD bạn sẽ được ghi 5 bài hát do bạn tự
            sáng tác.</h4>
          <div>Còn {chipDetail?.credit} lần</div>
          {chipDetail?.credit > 0 && (
            <Link
              to={`/item/${chipID}/style`}
              className='bg-black/[0.5] p-1 pl-3 w-fit flex items-center space-x-[10px] text-white text-[12px] rounded-[13px]'
            >
              <span>Sáng tác ngay</span>
              <div
                className='bg-black/[0.6] rounded-[50%] flex items-center justify-center w-4 h-4'>
                <Icon name={'add'} width={10} height={10}/>
              </div>
            </Link>
          )}
        </div>
      }

    </div>
  )
}
