import { BACKEND_API } from "../constant/endpoint"
import { removeVietnameseTones } from "../utils/string";

export const getAllQuotes = async ({page = 1, limit = 10, search}: {
  page: number;
  limit: number;
  search?: string
}) => {
  const offset = (page - 1) * limit
  let url = `${BACKEND_API}/blessings?limit=${limit}&offset=${offset}`
  if (search && search.length > 0) {
    url += `&search=${removeVietnameseTones(search)}`
  }

  const rs = await fetch(url)
  const rsJSON = await rs.json()

  if (rsJSON.error) {
    throw new Error(rsJSON.error.error_msg || 'Unknown error')
  }

  return rsJSON.data || []
}

export const getAllPronouns = async ({page = 1, limit = 10, search}: {
  page: number;
  limit: number;
  search?: string
}) => {
  const offset = (page - 1) * limit
  let url = `${BACKEND_API}/blessing-subjects?limit=${limit}&offset=${offset}`
  if (search && search.length > 0) {
    url += `&search=${removeVietnameseTones(search)}`
  }

  const rs = await fetch(url)
  const rsJSON = await rs.json()

  if (rsJSON.error) {
    throw new Error(rsJSON.error.error_msg || 'Unknown error')
  }

  return rsJSON.data.map((item: Record<string, any>) => ({
    id: item.id,
    title: item.name
  })) || []
}