import { useQuery } from "@tanstack/react-query"
import { getClipDetail } from "../services/music"
import { useState } from "react"

export const useClipDetail = (id: string) => {
  const [done, setDone] = useState(false)
  const {data, isPending, refetch} = useQuery({
    queryKey: ['clip-detail', id],
    queryFn: async () => {
      if (!id || id === '') return undefined
      const rs = await getClipDetail(id)
      if (rs.status === "complete") {
        setDone(true)
      }
      return rs
    },
    refetchInterval: 5000,
    enabled: !done
  })

  return {
    data, isPending, refetch
  }
}