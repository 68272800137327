import { StateCreator } from 'zustand'
import { fetchNewToken, getSessionStatus, triggerLogin } from '../services/auth';
import { v4 as uuidv4 } from 'uuid';
import keccak256 from 'keccak256';
import * as Sentry from "@sentry/browser";

export type AuthState = {
  phoneNumber: string;
  token: string;
	refreshToken: string;
	expiredAt: number;
	sessionId: string;
}

export type AuthActions = {
  triggerLoginByPhone: (phoneNumber: string) => Promise<{otp: string, authPhone: string}>;
	fetchNewToken: () => Promise<void>;
	fetchSessionStatus: () => Promise<void>;
	logout: () => void
}

export type AuthSlice = AuthState & AuthActions

export const createAuthSlice: StateCreator<AuthSlice, [], [], AuthSlice> = (set, get) => ({
  phoneNumber: '',
  token: '',
  refreshToken: '',
  expiredAt: 0,
  sessionId: '',
  triggerLoginByPhone: async (phoneNumber) => {
    const id = uuidv4();
    const hash = keccak256(id)
    set({sessionId: `0x${hash.toString('hex')}`, phoneNumber})

    const rs = await triggerLogin(hash.toString('hex'), phoneNumber)
    return {
      otp: rs.data.otp,
      authPhone: rs.data.phone_number
    }
  },
  fetchSessionStatus: async () => {
    try {
      const sessionId = get().sessionId
      if (!sessionId) return;
      const phone = get().phoneNumber
      const authResponse = await getSessionStatus(sessionId, phone)

      if (authResponse.data) {
        set({
          refreshToken: authResponse.data.refresh_token,
          token: authResponse.data.token,
          expiredAt: authResponse.data.expired_at * 1000,
          sessionId: ''
        })
        Sentry.setUser({ id: phone });
      }
    } catch (error) {
      console.log('session not verified')
    }
  },
  fetchNewToken: async () => {
    // TODO: fetch new token using refreshToken
    const token = get().refreshToken
    const authResponse = await fetchNewToken(token)
    set({
      refreshToken: authResponse.data.refresh_token,
      token: authResponse.data.token,
      expiredAt: authResponse.data.expired_at * 1000,
      sessionId: ''
    })
  },
  logout: () => {
    set({
      refreshToken: '',
      token: '',
      expiredAt: 0,
      sessionId: '',
      phoneNumber: ''
    })
    Sentry.setUser(null);
  }
})
