import { Button, TextInput } from 'flowbite-react'
import Background from '../../assets/preview_page_background.png'
import Header from '../../components/Header'
import { useEffect, useState } from 'react'
import { isPhoneNumber } from '../../utils/string'
import useAuth from '../../hooks/useAuth'
import QRModal from './QRModal'
import { useNavigate } from 'react-router-dom'
import * as Sentry from "@sentry/react";
import Footer from '../../components/Footer'

export default function LoginPage() {
  const navigate = useNavigate();
  const {triggerLoginByPhone, token, sessionId} = useAuth()

  const [phone, setPhone] = useState('')
  const [showQRModal, setShowQRModal] = useState(false)
	const [otpCode, setOTPCode] = useState('')
  const [authPhone, setAuthPhone] = useState('')
  const [error, setError] = useState('')

  useEffect(() => {
    if (token) {
      setShowQRModal(false)
      navigate(-1)
    } else if (sessionId) {
      setShowQRModal(true)
    }
  }, [token, sessionId])

  const handleLogin = async () => {
		try {
			setError('')
			setOTPCode('')
			if (!isPhoneNumber(phone)) {
				setError('Số điện thoại không hợp lệ')
				return
			}

			const {otp, authPhone: _authPhone} = await triggerLoginByPhone(phone)
      console.log(otp)
			setOTPCode(otp)
      console.log(otp)
			setAuthPhone(_authPhone)
      console.log(_authPhone)

			setShowQRModal(true)


		} catch (error) {
      Sentry.captureException(error, {data: {function: 'handleLogin'}})
			console.log(error)
		}
	}

  return (
    <div className="h-dvh flex flex-col items-center bg-cover"
      style={{
        backgroundImage: `url(${Background})`
      }}
    >
      <Header />
      <QRModal
        code={otpCode}
        phoneNumber={authPhone}
        open={showQRModal}
        handleClose={() => setShowQRModal(false)}
      />
      <div className='flex flex-col flex-1'>
        <div className='font-bold text-[20px] leading-[25px] text-center mt-[48px]'>
          Nhập số điện thoại
        </div>
        <div className='text-[14px] leading-[17.5px] text-center text-[#1F1F1F] mb-[32px] mt-2'>
          Để thêm lượt sáng tác
        </div>
        <TextInput
          type="text"
          addon="+84"
          className='w-full bg-white rounded-lg'
          color="white"
          placeholder='Nhập số điện thoại'
          value={phone}
          helperText={
            error ?
              <>
                <span className="font-medium text-xs text-red-500">{error}</span>
              </>
            : null
          }
          onChange={(event) => {
            setPhone(event.target.value)
          }}
        />
      </div>
      <div className='p-[16px] w-full'>
        <Button color="dark" fullSized onClick={handleLogin}>
          <span>OK</span>
        </Button>
      </div>
      <Footer color='black' />
    </div>
  )
}