import { Button } from "flowbite-react";
import Header from "../../components/Header";
import Icon from "../../components/Icon";
import { useMixSound } from "../../hooks/useMixSound";
import SongSearchComponent from "./SongSearchComponent";
import { useNavigate, useParams } from "react-router";
import Footer from "../../components/Footer";
import * as Sentry from "@sentry/react";

export default function MixPage() {
  let navigate = useNavigate();
  let { itemID } = useParams();
  const {soundID, setSoundID, mutateAsync: mixSound} = useMixSound()

  const handleMix = async () => {
    try {
      if (!itemID) return
      const rs = await mixSound(itemID)
      const clipIDs = rs.data
      navigate(`/clip/${clipIDs[0]}`)
    } catch (error) {
      Sentry.captureException(error, {data: {function: 'handleMix'}})
      console.log(error)
    }
  }

  return (
    <div className="antialiased min-h-screen flex flex-col items-center justify-between bg-black">
      <video autoPlay muted loop className="top-0 left-0 absolute z-0" playsInline>
        {/* <source type="video/mp4" src="/pick_song_background.mp4" /> */}
        <source type="video/mp4" src="/main_background.mp4" />
      </video>
      <div className="w-full z-10 flex flex-col items-center">
        <Header />
        <div className="flex flex-col items-end justify-center w-full p-[26px]">
          <span className="text-black text-[36px] font-bold">02</span>
          <span className="uppercase text-[12px] font-bold leading-[15px]">CHỌN VIBE</span>
        </div>
        <div className="flex-1 flex flex-col gap-[62px] items-center justify-center pt-[140px]">
          <div className="gap-[12px] flex flex-col items-center">
            <span className="mb-[13.5px]">
              Chọn bài hát bạn muốn ghi vào đĩa
            </span>
            <SongSearchComponent
              soundID={soundID}
              onSelect={(id: string) => {
                setSoundID(id)
              }}
            />
          </div>
          <Button onClick={handleMix} color="dark" pill size="xs" disabled={soundID === ''}>
            <div className="gap-2 flex items-center px-[28px]">
              <span className="leading-[17.5px] text-[14px] font-[500] text-[#D9D9D9]">
                SÁNG TÁC
              </span>
              <Icon name="music-note-plus" />
            </div>
          </Button>
        </div>
      </div>
      <div className="w-full relative">
        <Footer color="white" />
      </div>
    </div>
  )
}