import { BACKEND_API } from "../constant/endpoint"

export const getMusicFromID = async (itemID: string) => {
  const url = `${BACKEND_API}/chips/${itemID}`
  const rs = await fetch(url)

  const rsJSON = await rs.json()

  if (rsJSON.error) {
    throw new Error(rsJSON.error.error_msg || 'Unknown error')
  }

  console.log(rsJSON.data)
  return {
    uuid: rsJSON.data.uuid,
    // name: rsJSON.data.style.name,
    // code: rsJSON.data.style.id,
    // logo: rsJSON.data.style.image_url,
    credit: rsJSON.data.credit,
    phoneNumber: rsJSON.data.phone_number
  }
}

export const getSoundDetail = async (soundID: string) => {
  const url = `${BACKEND_API}/songs/${soundID}`

  const rs = await fetch(url)
  const rsJSON = await rs.json()

  if (rsJSON.error) {
    throw new Error(rsJSON.error.error_msg || 'Unknown error')
  }

  return rsJSON.data
}

export const searchSound = async (page: number, limit: number, query: string) => {
  const offset = (page - 1) * limit
  let url = `${BACKEND_API}/songs?limit=${limit}&offset=${offset}`

  if (query) {
    url += `&search=${encodeURI(query)}`
  }

  const rs = await fetch(url)
  const rsJSON = await rs.json()

  if (rsJSON.error) {
    throw new Error(rsJSON.error.error_msg || 'Unknown error')
  }

  return rsJSON.data
}

export const mixSound = async ({chipID, styleID, soundID}: {
  chipID: string,
  // quote: string,
  soundID: string,
  // pronoun: string
  styleID: string,
}) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  const raw = JSON.stringify({
    "chip_uuid": chipID,
    "style_id": styleID,
    "song_id": soundID,
    // "title": pronoun
  });
  const requestOptions: Record<string, any> = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow"
  };

  const rs = await fetch(`${BACKEND_API}/clips/gen`, requestOptions)
  const rsJSON = await rs.json()
  if (rsJSON.error) {
    throw new Error(rsJSON.error.error_msg || 'Unknown error')
  }
  return rsJSON
} 

export const getClipDetail = async (clipID: string) => {
  let url = `${BACKEND_API}/clips/${clipID}`

  const rs = await fetch(url)
  const rsJSON = await rs.json()
  return rsJSON.data
}

export const getClipsFromChipID = async (chipID: string) => {
  let url = `${BACKEND_API}/clips?chip_uuid=${chipID}`

  const rs = await fetch(url)
  const rsJSON = await rs.json()

  if (rsJSON.error) {
    throw new Error(rsJSON.error.error_msg || 'Unknown error')
  }

  return rsJSON.data
}

export const getAllStyle = async () => {
  let url = `${BACKEND_API}/styles?offset=0&limit=10000`

  const rs = await fetch(url)
  const rsJSON = await rs.json()

  if (rsJSON.error) {
    throw new Error(rsJSON.error.error_msg || 'Unknown error')
  }

  return rsJSON.data
}