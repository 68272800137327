import MainBackground from '../../assets/main_bg.png'

export default function UnsupportedDevices() {

	return (
		<div
      className="h-dvh px-[16px] flex flex-col items-center justify-start bg-cover"
      style={{
        backgroundImage: `url(${MainBackground})`
      }}
    >
      <div className='h-full justify-center items-center flex px-[16px]'>
        <div className="text-[28px] text-center font-bold pt-[17px] pb-[72px]">
          Thiết bị không hỗ trợ. Xin truy cập bằng điện thoại.
        </div>
      </div>
		</div>
	)
}