import { useParams } from "react-router";
import Header from "../../components/Header";
// import { useItemDetail } from "../../hooks/useItemDetail";
import { Alert, Badge, Button, Dropdown, Label, TextInput } from "flowbite-react";
import { HiChevronDown } from "react-icons/hi";
import Icon from "../../components/Icon";
import { useMixSound } from "../../hooks/useMixSound";
import { useSoundDetail } from "../../hooks/useSoundDetail";
import { useAllQuotes } from "../../hooks/useAllQuotes";
import { useAllPronouns } from "../../hooks/useAllPronouns";
import { useNavigate } from "react-router-dom";
import { truncate } from "../../utils/string";
import { useMemo, useState } from "react";
import { useDebounce } from "../../hooks/useDebounce";
import InfiniteScroll from "react-infinite-scroll-component";
import * as Sentry from "@sentry/react";
import Footer from "../../components/Footer";

export default function SelectLyric() {
  let navigate = useNavigate();
  let { itemID } = useParams();
  const { soundID, quote, setQuote, pronoun, setPronoun, mutateAsync: mixSound, error, reset, isPending } = useMixSound()

  // const { item } = useItemDetail(itemID || '')
  const { data: soundData } = useSoundDetail(soundID)

  const [quoteSearch, setQuoteSearch] = useState('')
  const debounceQuoteSearch = useDebounce(quoteSearch, 300)
  const { data: allQuotesPaged, fetchNextPage } = useAllQuotes(debounceQuoteSearch)

  const allQuotes = useMemo(() => {
    if (!allQuotesPaged) return []
    return allQuotesPaged.pages.flat()
  }, [allQuotesPaged])

  const hasMoreQuote = useMemo(() => {
    const _hasMore = allQuotesPaged?.pages[allQuotesPaged?.pages.length - 1] &&
      allQuotesPaged?.pages[allQuotesPaged?.pages.length - 1].length > 0
      ? true
      : false
    return _hasMore
  }, [allQuotesPaged])

  const [pronounSearch, setPronounSearch] = useState('')
  const debouncedPronounSearch = useDebounce(pronounSearch, 300)
  const { data: allPronounsPaged, fetchNextPage: fetchNextPagePronoun } = useAllPronouns(debouncedPronounSearch)

  const allPronouns = useMemo(() => {
    if (!allPronounsPaged) return []
    return allPronounsPaged.pages.flat()
  }, [allPronounsPaged])

  const hasMorePronoun = useMemo(() => {
    const _hasMore = allPronounsPaged?.pages[allPronounsPaged?.pages.length - 1] &&
      allPronounsPaged?.pages[allPronounsPaged?.pages.length - 1].length > 0
      ? true
      : false
    return _hasMore
  }, [allPronounsPaged])

  const handleMix = async () => {
    try {
      if (!itemID) return
      const rs = await mixSound(itemID)
      const clipIDs = rs.data
      navigate(`/clip/${clipIDs[0]}`)
    } catch (error) {
      Sentry.captureException(error, {data: {function: 'handleMix'}})
      console.log(error)
    }
  }

  return (
    <div className="antialiased min-h-screen flex flex-col items-center">
      <Header />
      <div className="flex gap-4 w-full mt-[24px] px-[16px]">
        <Badge className="bg-black text-white rounded-[50px] text-[14px] leading-[17.5px]" size="md">
          {/* {item?.name} */}
          Name 123
        </Badge>
        <Badge className="bg-black text-white rounded-[50px] text-[14px] leading-[17.5px]" size="md">
          {soundData?.name}
        </Badge>
      </div>
      <div className="flex flex-col flex-1 w-full pt-[24px] gap-[32px] px-[16px]">
        <div className="w-full">
          <div className="mb-2 block">
            <Label htmlFor="quote" value="Lời chúc" />
          </div>
          <Dropdown
            label=""
            id="quote"
            // dismissOnClick={false}
            renderTrigger={() => {
              return (
                <div className="w-full border-[1px] border-[#F0F0F0] px-[10px] py-[8px] flex justify-between items-center">
                  <span>{quote || "Chọn lời chúc"}</span>
                  <HiChevronDown />
                </div>
              )
            }}
          >
            <div>
              <TextInput type="text" className='w-full sticky top-0'
                color='white'
                placeholder='Tìm kiếm lời chúc...'
                value={quoteSearch}
                onKeyDown={(event) => {
                  event.stopPropagation()
                }}
                onChange={(event) => {
                  setQuoteSearch(event.target.value)
                }}
              />
              <div
                className='max-h-[50dvh] overflow-auto'
                id="quoteList"
              >
                <InfiniteScroll
                  dataLength={allQuotes.length}
                  next={fetchNextPage}
                  hasMore={hasMoreQuote}
                  loader={<h4 className="text-white text-center">Loading...</h4>}
                  scrollableTarget="quoteList"
                >
                  {allQuotes.map((item: Record<string, any>, index: number) => {
                    return (
                      <Dropdown.Item key={`quote-${item.id}-${index}`} onClick={() => {
                        setQuote(item.content)
                        setQuoteSearch('')
                      }}>
                        {/* {item.title} */}
                        {truncate(item.content, 40)}
                      </Dropdown.Item>
                    )
                  })}
                </InfiniteScroll>
              </div>
            </div>
          </Dropdown>
        </div>
        <div className="w-full">
          <div className="mb-2 block">
            <Label htmlFor="quote" value="Xưng hô" />
          </div>
          <Dropdown
            label=""
            id="quote"
            // dismissOnClick={false}
            renderTrigger={() => {
              return (
                <div className="w-full border-[1px] border-[#F0F0F0] px-[10px] py-[8px] flex justify-between items-center">
                  <span>{pronoun || "Chọn xưng hô"}</span>
                  <HiChevronDown />
                </div>
              )
            }}
          >
            <div>
              {/* TODO: Add search input */}
              <TextInput type="text" className='w-full sticky top-0'
                color='white'
                placeholder='Tìm kiếm xưng hô...'
                value={pronounSearch}
                onKeyDown={(event) => {
                  event.stopPropagation()
                }}
                onChange={(event) => {
                  setPronounSearch(event.target.value)
                }}
              />
              <div
                className='max-h-[40dvh] overflow-auto'
                id="pronounList"
              >
                <InfiniteScroll
                  dataLength={allPronouns.length}
                  next={fetchNextPagePronoun}
                  hasMore={hasMorePronoun}
                  loader={<h4 className="text-white text-center">Loading...</h4>}
                  scrollableTarget="pronounList"
                >
                  {allPronouns.map((item: Record<string, any>, index: number) => {
                    return (
                      <Dropdown.Item
                        key={`pronoun-${item.id}-${index}`}
                        onClick={() => {
                          setPronoun(item.title)
                          setPronounSearch('')
                        }}
                      >
                        {item.title}
                      </Dropdown.Item>
                    )
                  })}
                </InfiniteScroll>
              </div>
            </div>
          </Dropdown>

        </div>
        {error && (
          <Alert color="failure" onDismiss={reset}>
            {/* <span className="font-medium">{error.message}</span> */}
            <span className="font-medium">Server error</span>
          </Alert>
        )}
      </div>
      <div className="px-[16px] w-full">
        <Button color="dark" fullSized pill className="mb-[16px]"
          onClick={handleMix} isProcessing={isPending}>
          <div className="gap-2 flex items-center px-[28px]">
            <span
              className="leading-[17.5px] text-[14px] font-[500] text-[#D9D9D9]">
              SÁNG TÁC
            </span>
            <Icon name="music-note-plus" />
          </div>
        </Button>
        <Footer color="black" />
      </div>
    </div>
  )
}
