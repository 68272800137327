import Background from '../../assets/preview_page_background.png'
import INTRO_GIF from '../../assets/CVG_Intro.gif'
import Footer from '../../components/Footer'
import Header from '../../components/Header'
import { useTranslation } from 'react-i18next'

export default function InstructionPage() {
  const {t} = useTranslation()
  return (
    <div
      className="min-h-dvh flex flex-col items-center bg-cover"
      style={{
        backgroundImage: `url(${Background})`
      }}
    >
      <Header />
      <div className='flex flex-1 px-[35px] flex-col relative'>
        <img src={INTRO_GIF} className='w-[259px] absolute -top-20 left-0' />
        <div className='text-[26px] mt-[289px] text-black leading-[23px] uppercase'>
          {t('instructionTitle')}
        </div>
        <div className='my-[36px] text-[14px] leading-[17.5px]'>
          <div>1. {t('step1')}</div>
          <div>2. {t('step2')}</div>
          <div>3. {t('step3')}</div>
          <div>4. {t('step4')}</div>
          <div>5. {t('step5')}</div>
        </div>
      </div>
      <Footer color='black' />
    </div>
  )
}