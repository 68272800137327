import { useQuery } from "@tanstack/react-query"
import { getClipsFromChipID } from "../services/music"

export const useAllClips = (chipID: string) => {
  const {data, isPending, refetch} = useQuery({
    queryKey: ['all-clips', chipID],
    queryFn: async () => {
      if (!chipID || chipID === '') return []
      return getClipsFromChipID(chipID)
    },
    initialData: []
  })

  return {
    data, isPending, refetch
  }
}