// import { Button } from 'flowbite-react'
import MainBackground from '../../assets/main_bg.png'
import Header from '../../components/Header'
// import { RiAppleFill } from "react-icons/ri";
// import { DiAndroid } from "react-icons/di";

export const ScanChipPage = () => {
  return (
    <div
      className="h-dvh px-[16px] flex flex-col items-center justify-start bg-cover"
      style={{
        backgroundImage: `url(${MainBackground})`
      }}
    >
      <Header />
      <div className='h-full justify-center items-center flex px-[16px]'>
        <div className="text-[28px] text-center font-bold pt-[17px] pb-[72px]">
          Scan chip để trải nghiệm
        </div>
      </div>
      {/* <div className='flex flex-col gap-4'>
        <div className='flex flex-1'>
          <Button color="dark" fullSized pill onClick={() => window.open("camera://", "_blank")}>
            <div className="gap-2 flex items-center px-[28px]">
              <RiAppleFill />
              <span className="leading-[17.5px] text-[14px] font-[500] text-[#D9D9D9]">
                iOS user
              </span>
            </div>
          </Button>
        </div>
        <div className='flex flex-1'>
          <Button color="dark" fullSized pill onClick={() => window.open("intent://camera", "_blank")}>
            <div className="gap-2 flex items-center px-[28px]">
              <DiAndroid />
              <span className="leading-[17.5px] text-[14px] font-[500] text-[#D9D9D9]">
                Android user
              </span>
            </div>
          </Button>
        </div>
      </div> */}
    </div>
  )
}