import { Drawer, TextInput } from "flowbite-react";
import { useMemo, useState } from "react";
import { useSoundDetail } from "../../hooks/useSoundDetail";
import Icon from "../../components/Icon";
import { useDebounce } from "../../hooks/useDebounce";
import { useSearchSound } from "../../hooks/useSearchSound";
import InfiniteScroll from "react-infinite-scroll-component";

export default function SongSearchComponent({
  soundID,
  onSelect,
}: {
  soundID: string;
  onSelect: (songID: string) => void;
}) {
  const [showSongSearch, setShowSongSearch] = useState(false)
  const {data: soundData} = useSoundDetail(soundID)

  const [searchQuery, setSearchQuery] = useState('')
  const debouncedQuery = useDebounce(searchQuery, 500)
  const {data: soundList, fetchNextPage} = useSearchSound(debouncedQuery)

  const soundListFlat = useMemo(() => {
    if (!soundList) return [];
    return soundList.pages.flat();
  }, [soundList])

  const hasMore = useMemo(() => {
    const _hasMore = soundList?.pages[soundList?.pages.length - 1] &&
          soundList?.pages[soundList?.pages.length - 1].length > 0
            ? true
            : false
    return _hasMore
  }, [soundList])

  const clearButton = () => {
    if (searchQuery.length > 0) {
      return (
        <button
          className="w-[14px] h-[14px] rounded-full bg-[#454545] flex items-center justify-center z-100"
          onClick={() => setSearchQuery('')}
        >
          <Icon name="close" width={8} height={12} />
        </button>
      )
    }

    return null
  }

  const handleSelect = (id: string) => {
    onSelect(id)
    setShowSongSearch(false)
  }

  return (
    <>
      <div
        className="min-w-[167px] bg-white/90 border-[1px] border-[#F5F5F5] rounded-[50px] px-[12px] py-[3px] flex items-center justify-center"
        onClick={() => setShowSongSearch(true)}
      >
        {soundData && soundID !== '' ? (
          <span className="text-[14px] leading-[17.5px]">{soundData.name}</span>
        ) : (
          <span className="text-[14px] text-[#BFBFBF] leading-[17.5px]">Tên bài hát</span>
        )}
      </div>
      <Drawer className="h-[400px] overflow-hidden bg-[#313131] rounded-t-[6px]" open={showSongSearch} onClose={() => setShowSongSearch(false)} position="bottom">
        <Drawer.Items className="mb-[16px]">
          <div className="flex gap-4">
            <TextInput
              className="flex-1 rounded-[2000px] bg-[#7D7D7D]"
              type="text"
              sizing="sm"
              rightIcon={clearButton}
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <button
              onClick={() => setShowSongSearch(false)}
              className="text-white font-[500] text-[14px] leading-[17.71px]"
            >
              Cancel
            </button>
          </div>
        </Drawer.Items>
        <Drawer.Items>
          <div
            className="overflow-auto h-[365px] pb-[32px]"
            id="scrollableDiv"
          >
            <InfiniteScroll
              dataLength={soundListFlat.length}
              next={fetchNextPage}
              hasMore={hasMore}
              // hasMore={true}
              loader={<h4 className="text-white text-center">Loading...</h4>}
              scrollableTarget="scrollableDiv"
            >
              {soundListFlat.map((sound, index) => {
                return (
                  <div key={`sound-${sound.id}-${index}`} className="h-[72px] flex items-center text-white gap-3" onClick={() => handleSelect(sound.id)}>
                    <div className="w-[28px]">{index + 1}</div>
                    <div className="flex gap-3">
                      {/* <img src={sound.avatar} className="w-[48px] h-[48px] rounded-[6px]" /> */}
                      <div className="flex flex-col gap-2 justify-center items-start">
                        <span className="text-[16px] leading-[20px] font-[500]">{sound.name}</span>
                        <span className="text-[12px] leading-[15px] text-[#BFBFBF]">{sound.author}</span>
                      </div>
                    </div>
                  </div>
                )
              })}
            </InfiniteScroll>
          </div>
        </Drawer.Items>
      </Drawer>
    </>
  )
}