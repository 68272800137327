import { useInfiniteQuery } from "@tanstack/react-query"
import { getAllQuotes } from "../services/lyric"

export const useAllQuotes = (search: string) => {
  const {data, isFetching, refetch, fetchNextPage} = useInfiniteQuery({
    queryKey: ['all-quotes', search],
    queryFn: async ({pageParam = 1}) => {
      return getAllQuotes({page: pageParam, limit: 20, search})
    },
    getNextPageParam: (lastPage, pages) => {
      const nextPageParam = lastPage.length === 0 ? undefined : pages.length + 1
      return nextPageParam
    },
    initialPageParam: 1
  })

  return {
    data,
    isFetching,
    refetch,
    fetchNextPage
  }
}