import Background from "../../assets/preview_page_background.png";
import Header from "../../components/Header";
import { usePhoneChip } from "../../hooks/usePhoneChip.tsx";
import { Link, useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth.ts";
import Footer from "../../components/Footer/index.tsx";

function ProfilePage() {
  const navigate = useNavigate();
  const { isLoggedIn } = useAuth()
  const { data } = usePhoneChip()

  if (!isLoggedIn) {
    navigate(-1)
    return null
  }

  return (
    <div className="h-dvh flex flex-col items-center bg-cover"
      style={{
        backgroundImage: `url(${Background})`
      }}>
      <Header />
      <h1 className='mt-10 font-[700] text-[16px] mb-[32px]'>PLAYLIST CỦA BẠN</h1>
      <div className='w-full gap-6 flex flex-col flex-1 justify-start px-[16px] overflow-scroll'>
        {data && data.map((item: Record<string, any>, index: number) => {
          return (
            <Link
              className="flex items-center justify-start gap-4 w-full"
              to={`/item/${item.uuid}`}
              key={`chip-${index}`}
            >
              <img className={`w-[60px] h-[60px] rounded-[6px]`} src={item.logo || "/asset/mascot.png"} />
              <div className="flex flex-col">
                <span className="font-bold text-[14px] leading-[17px]">Chip {index + 1}</span>
                <div className="flex gap-2 items-center">
                  <span className="text-[#4F4F4F] font-[500] text-[12px] leading-[15px]">Playlist</span>
                  <span className="text-[#777777] text-[12px] leading-[15px] font-[500]">
                    {item.totalClips} / {item.totalClips + item.credit} songs
                  </span>
                </div>
              </div>
            </Link>
          )
        })}
      </div>
      <Footer color="black" />
    </div>
  )
}

export default ProfilePage
