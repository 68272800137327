import Icon from "../../components/Icon";
import {useEffect, useRef, useState} from "react";
import { downloadResource } from "../../utils/file";
import { useSoundDetail } from "../../hooks/useSoundDetail";
import { useParams } from "react-router-dom";
import { useClipDetail } from "../../hooks/useClipDetail";

export default function AudioPlayer({src, songID}: { src: '' | string, songID: string }) {
  let { clipID } = useParams();

  const {data: clipData} = useClipDetail(clipID || '')

  const {data: songDetail} = useSoundDetail(songID)
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [isMuted, setIsMuted] = useState<boolean>(false);

  const audioRef = useRef<HTMLAudioElement>(null);

  const togglePlay = () => {
    if (isPlaying) {
      audioRef.current?.pause();
    } else {
      audioRef.current?.play();
    }
    setIsPlaying(!isPlaying);
  };
  const toggleMute = () => {
    if (!audioRef.current) return
    audioRef.current.muted = !isMuted;
    setIsMuted(!isMuted);
  };

  const handlePlay = () => {
    setIsPlaying(true);
  };

  const handlePause = () => {
    setIsPlaying(false);
  };


  useEffect(() => {
    const handleEnded = () => {
      if (audioRef.current) {
        setIsPlaying(false);
        audioRef.current.currentTime = 0; // rewind to beginning
      }
    };

    const handleTimeUpdate = () => {
      // if (audioRef.current) {
      //   if (audioRef.current.currentTime >= 30) {
      //     audioRef.current.pause();
      //     setIsPlaying(false);
      //     audioRef.current.currentTime = 0; // rewind to beginning
      //   }
      // }
    };

    if (audioRef.current) {
      audioRef.current.addEventListener('ended', handleEnded);
      audioRef.current.addEventListener('timeupdate', handleTimeUpdate);
      audioRef.current.addEventListener('play', handlePlay);
      audioRef.current.addEventListener('pause', handlePause);
    }

    return () => {
      if (audioRef.current) {
        audioRef.current.removeEventListener('ended', handleEnded);
        audioRef.current.removeEventListener('timeupdate', handleTimeUpdate);
        audioRef.current.removeEventListener('play', handlePlay);
        audioRef.current.removeEventListener('pause', handlePause);
      }
    };
  }, [audioRef, isPlaying]); // Re-run effect when audioRef or isPlaying changes


  return (
    <div className='w-full px-5 mt-[60px] flex items-center justify-evenly'>
      <audio ref={audioRef} src={src}/>
      <button onClick={() => toggleMute()}>
        {isMuted ? <Icon name={'mute'} height={16} width={16}/>
          : <Icon name={'unmute'} height={16} width={16}/>
        }
      </button>
      <button
        onClick={() => {
          togglePlay()
        }}
        className='w-[70px] h-[70px] bg-gradient-to-br from-[#F1F1F1] to-white rounded-[50%] flex items-center justify-center border-[2px] border-[#F1F1F1]'>
        {isPlaying ? <Icon name='stop' width={30} height={30}/>
          : <Icon name='play' width={30} height={30}/>
        }

      </button>
      {clipData.status === 'complete' ? (
        <button onClick={() => downloadResource(src, `${songDetail.name}.mp3`)} className='w-4'>Download</button>
      ): (
        <span className='w-4'>
          Processing...
        </span>
      )}
    </div>
  )
}
