import MainPage from "./pages/Main";
import { createBrowserRouter, createRoutesFromChildren, matchRoutes, RouterProvider, useLocation, useNavigationType } from "react-router-dom";
import ProfilePage from "./pages/Profile";
import MixPage from "./pages/Mix";
import { Flowbite } from "flowbite-react";
import { customTheme } from "./theme";
import SelectLyric from "./pages/SelectLyric";
import PreviewPage from "./pages/Preview";
import { ScanChipPage } from "./pages/Scan";
import { useEffect } from "react";
import useAuth from "./hooks/useAuth";
import LoginPage from "./pages/Login";
import * as Sentry from "@sentry/react";
import React from "react";
import { MOBILE_WIDTH } from "./constant/config";
import UnsupportedDevices from "./pages/UnsupportedDevices";
import { useWindowSize } from "usehooks-ts";
import SelectStyle from "./pages/SelectStyle";
import "./i18n"
import InstructionPage from "./pages/Instruction";

Sentry.init({
  dsn: "https://220a3b88f8abeafbbe826b273008eee7@o495346.ingest.us.sentry.io/4507802031751168",
  integrations: [
    // Sentry.browserTracingIntegration(),
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const router = createBrowserRouter([
  {
    path: "/",
    element: <ScanChipPage />,
  },
  {
    path: "/item/:itemID",
    element: <MainPage />,
  },
  {
    path: "/item/:itemID/style",
    element: <SelectStyle />,
  },
  {
    path: "/item/:itemID/mix",
    element: <MixPage />,
  },
  {
    path: "/item/:itemID/lyric",
    element: <SelectLyric />,
  },
  {
    path: "/profile",
    element: <ProfilePage />,
  },
  {
    path: "/clip/:clipID",
    element: <PreviewPage />,
  },
  {
    path: "/login",
    element: <LoginPage />
  },
  {
    path: "/tutorial",
    element: <InstructionPage />
  },
  
]);

function App() {
  const { width } = useWindowSize()
  const { expiredAt, fetchNewToken, refreshToken } = useAuth()

  useEffect(() => {
    if (expiredAt < Date.now() && refreshToken) {
      fetchNewToken()
    }
  }, [expiredAt, fetchNewToken, refreshToken])

  return (
    <Flowbite theme={{theme: customTheme}}>
      {
        width > MOBILE_WIDTH ? (
          <UnsupportedDevices />
        ) : (
          <RouterProvider router={router} />
        )
      }
    </Flowbite>
  )
}

export default App;
