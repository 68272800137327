'use client'

import { type ReactNode, createContext, useRef, useContext } from 'react'
import { type StoreApi, useStore } from 'zustand'

import { type BoundStore, createBoundStore } from '../stores/bound'

export const BoundStoreContext = createContext<StoreApi<BoundStore> | null>(
  null,
)

export interface BoundStoreProviderProps {
  children: ReactNode
}

export const BoundStoreProvider = ({
  children,
}: BoundStoreProviderProps) => {
  const storeRef = useRef<StoreApi<BoundStore>>()
  if (!storeRef.current) {
    storeRef.current = createBoundStore()
  }

  return (
    <BoundStoreContext.Provider value={storeRef.current}>
      {children}
    </BoundStoreContext.Provider>
  )
}

export const useBoundStore = <T,>(
  selector: (store: BoundStore) => T,
): T => {
  const boundStoreContext = useContext(BoundStoreContext)

  if (!boundStoreContext) {
    throw new Error(`useBoundStore must be use within BoundStoreContext`)
  }

  return useStore(boundStoreContext, selector)
}