import { CustomFlowbiteTheme } from "flowbite-react";

export const customTheme: CustomFlowbiteTheme = {
  button: {
    color: {
      primary: "bg-red-500 hover:bg-red-600",
    },
  },
  textInput: {
    addon: "inline-flex items-center rounded-l-md bg-transparent px-3 text-sm text-gray-900",
    field: {
      input: {
        colors: {
          gray: "bg-[#7D7D7D] text-white focus:border-none focus:ring-transparent dark:text-white dark:placeholder-gray-400 dark:focus:border-none dark:focus:ring-transparent"
        },
        sizes: {
          sm: "text-[12px] px-[12px] py-[6px] leading-[13.8px]"
        },
        withAddon: {
          off: "rounded-[2px]",
          on: "rounded-r-lg border-transparent focus:border-none focus:ring-transparent"
        }
      }
    }
  }
};