import { Link, useParams } from "react-router-dom";
import Header from "../../components/Header"
import { useItemDetail } from "../../hooks/useItemDetail";
import { Button } from "flowbite-react";
import Icon from "../../components/Icon";
import { useAllClips } from "../../hooks/useAllClips";
import Background from '../../assets/preview_page_background.png'
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCoverflow, Grid } from 'swiper/modules';
import { HiPlusCircle } from "react-icons/hi";
import MainBackground from '../../assets/main_bg.png'

import 'swiper/css';
import 'swiper/css/grid';
import 'swiper/css/effect-coverflow';
import { isIOS } from "react-device-detect";
import useAuth from "../../hooks/useAuth";
import { usePhoneChip } from "../../hooks/usePhoneChip";
import { useMemo } from "react";
import Footer from "../../components/Footer";
import { useTranslation } from "react-i18next";

function MainPage() {
  const {t} = useTranslation()
  const {isLoggedIn, bindChip, isPending} = useAuth()
  let { itemID } = useParams();

  const {item, refetch: refetchItemDetail, error: errorItemDetail, isFetching} = useItemDetail(itemID || '')
  const {data: allClips} = useAllClips(itemID || '')
  const {refetch} = usePhoneChip()

  const alreadyLinked = useMemo(() => {
    return item?.phoneNumber && item.phoneNumber !== ""
  }, [item])

  if (isFetching) {
    return (
      <div
        className="h-dvh px-[16px] flex flex-col items-center justify-start bg-cover"
        style={{
          backgroundImage: `url(${MainBackground})`
        }}
      >
        <Header />
        <div className='h-full justify-center items-center flex px-[16px]'>
          <div className="text-[28px] text-center font-bold pt-[17px] pb-[72px]">
            Loading...
          </div>
        </div>
      </div>
    )
  }

  if (errorItemDetail) {
    console.log(errorItemDetail)
    return (
      <div
        className="h-dvh px-[16px] flex flex-col items-center justify-start bg-cover"
        style={{
          backgroundImage: `url(${MainBackground})`
        }}
      >
        <Header />
        <div className='h-full justify-center items-center flex flex-col px-[16px]'>
          <div className="text-[28px] text-center font-bold pt-[17px]">
            Chip Error
          </div>
          {/* <div className="text-[14px] text-red-800 text-center italic font-bold pt-[17px] pb-[72px]">
            {errorItemDetail.message}
          </div> */}
        </div>
      </div>
    )
  }

  if (allClips.length > 0) {
    return (
      <div
        className="h-dvh flex flex-col items-center bg-cover"
        style={{
          backgroundImage: `url(${Background})`
        }}
      >
        <Header />
        <h2 className="text-[16px] leading-[20.24px] font-bold pt-[17px] pb-[72px]">YOUR PLAYLIST</h2>
        <Swiper
          modules={[
            EffectCoverflow,
            Grid
          ]}
          className={`w-full min-h-[270px] ${isIOS ? 'px-[16px]' : ''}`}
          effect={isIOS ? undefined : 'coverflow'}
          coverflowEffect={{
            rotate: 20,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: false,
          }}
          // centeredSlides={true}
          spaceBetween={30}
          slidesPerView={3}
          grid={{
            rows: 2,
            fill: 'row'
          }}
        >
          {allClips.map((clip: Record<string, any>, index: number) => {
            return (
              <SwiperSlide
                key={`clip-${index}`}
                className="flex items-center justify-center"
              >
                <Link key={`clip-${index}`} to={`/clip/${clip.id}`}>
                  <img
                    src={clip.image_url}
                    className="rounded-[12px]"
                  />
                </Link>
              </SwiperSlide>
            )
          })}
          {allClips.length < item?.credit && (
            <SwiperSlide className="flex items-center justify-center">
              <Link to={`/item/${itemID}/style`} className="aspect-square">
                <div className="w-full h-full rounded-[12px] bg-[#000000]/[.5] p-[16px] flex flex-col justify-between">
                  <div className="text-[14px] leading-[15px] text-white">
                    Sáng tác thêm
                  </div>
                  <div className="flex justify-end items-end">
                    <div>
                      <HiPlusCircle className="w-[28px] h-[28px]" stroke="white" />
                    </div>
                  </div>
                </div>
              </Link>
            </SwiperSlide>
          )}
        </Swiper>
        <div className="pt-[64px] flex-1">
          {isLoggedIn ? (
            <Button
              color="dark"
              fullSized
              pill
              isProcessing={isPending}
              onClick={async () => {
                if (!itemID || alreadyLinked) return
                await bindChip(itemID)
                refetch()
                refetchItemDetail()
              }}
              disabled={alreadyLinked}
            >
              <span>
                {!alreadyLinked ? "Thêm lượt sáng tác" : "Đã kết nối" }
              </span>
            </Button>
          ) : (
            <Link
              to="/login"
            >
              <Button color="dark" fullSized pill>
                <span>Đăng nhập để thêm lượt sáng tác</span>
              </Button>
            </Link>
          )}
        </div>
        <Footer color="black" />
      </div>
    )
  }

  return (
    <div
      className="h-dvh flex flex-col items-center bg-cover"
      style={{
        backgroundImage: `url(${Background})`
      }}
    >
      <div className="z-10 w-full flex flex-1 flex-col items-center">
        <Header />
        <div className="px-[23px] py-[32px] w-full">
          <div className="rounded-[22px] bg-[#D0D0D0]/[0.5] w-full flex flex-col items-center justify-center py-[40px] px-[28px]">
            <div className="text-black font-bold text-[22px] leading-[28px] w-[220px] text-center">
              {t('title')}
            </div>
            <div className="text-center mt-[14px] text-[#1F1F1F] text-[15px] leading-[19px] w-[280px] text-center">
              {t('homeDescription')}
            </div>
            <img src="/asset/mascot.png" className="w-[220px]" />
            <Link to={`/item/${itemID}/style`}>
              <Button color="dark" pill size="xs">
                <div className="gap-2 flex items-center px-[28px]">
                  <span className="uppercase leading-[17.5px] text-[14px] font-[500] text-[#D9D9D9]">
                    {t('homeCTAButton')}
                  </span>
                  <Icon name="music-note-plus" />
                </div>
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MainPage
