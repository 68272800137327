'use client'
import Header from "../../components/Header";
import Background from '../../assets/preview_page_background.png'
import { useState } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

import { EffectCreative } from 'swiper/modules';
import AudioPlayer from "./AudioPlayer.tsx";
import SongCard from "./SongCard.tsx";
import { useParams } from "react-router-dom";
import { useClipDetail } from "../../hooks/useClipDetail.tsx";
import { truncate } from "../../utils/string.ts";
import { useItemDetail } from "../../hooks/useItemDetail.tsx";
import Footer from "../../components/Footer/index.tsx";

export default function PreviewPage() {
  const [activeSlide, setActiveSlide] = useState<number>(1)
  let { clipID } = useParams();

  const {data: clipData} = useClipDetail(clipID || '')
  const {item: chipDetail} = useItemDetail(clipData ? clipData.chip_uuid : '')

  if (!clipData || !chipDetail) return null
  
  return (
    <div className="h-dvh flex flex-col items-center bg-cover"
      style={{
        backgroundImage: `url(${Background})`
      }}>
      <Header chipID={clipData.chip_uuid} />
      <div className="flex flex-col items-end justify-center w-full p-[26px]">
        <span className="text-black text-[36px] font-bold">03</span>
        <span className="uppercase text-[12px] font-bold leading-[15px]">NGHE NHẠC</span>
      </div>
      <div
        className="flex w-full flex-1 flex-col justify-center items-center"
      >
        <Swiper
          modules={[EffectCreative]}
          className={`w-full`}
          style={{
            perspective: '500px',
            perspectiveOrigin: "center"
          }}
          effect='creative'
          creativeEffect={{
            prev: {
              translate: [-80, 0, -100],
              rotate: [0, 45, 0]
            },
            next: {
              // will set `translateX(100%)` on next slides
              translate: [80, 0, -100],
              rotate: [0, -45, 0]
            },
          }}
          centeredSlides={true}
          spaceBetween={0}
          slidesPerView={3}
          onSlideChange={(swiper) => {
            setActiveSlide(swiper.activeIndex + 1)
          }}
        >
          <SwiperSlide className='flex w-full justify-center'>
            <SongCard imageURL={clipData.image_url} />
          </SwiperSlide>
          <SwiperSlide className='flex w-full justify-center'>
            <SongCard chipID={clipData.chip_uuid} />
          </SwiperSlide>
        </Swiper>
        <div
          className='mt-[40px] rounded-[26px] bg-white/[0.3] px-3 py-[5px] text-[12px] text-black/[0.4] font-oswald'>
          <span className='text-black'>{activeSlide}</span>/2
        </div>
        <div className='w-full px-5 mt-[60px]'>
          <h1 className='text-[#141414] text-[24px] font-[600]'>
            {truncate(clipData.title, 20)}
          </h1>
          <h2 className='text-[#8C8C8C]'>{clipData.style.name}</h2>
        </div>
        {(clipData.status !== "streaming" && clipData.status !== "complete") ? (
          <span>Processing...</span>
        ) : (
          <AudioPlayer
            src={clipData.audio_url}
            songID={clipData.song_id}
          />
        )}
      </div>
      <Footer color="black"/>
    </div>
  )
}
