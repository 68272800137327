import { useInfiniteQuery } from "@tanstack/react-query"
import { searchSound } from "../services/music"

export const useSearchSound = (queryString: string) => {
  const {data, fetchNextPage, isFetching} = useInfiniteQuery({
    queryKey: ['search-song', queryString],
    queryFn: async ({pageParam = 1}) => {
      return searchSound(pageParam, 10, queryString)
    },
    getNextPageParam: (lastPage, pages) => {
      const nextPageParam = lastPage.length === 0 ? undefined : pages.length + 1
      return nextPageParam
    },
    initialPageParam: 1
  })

  return {
    data, fetchNextPage, isFetching
  }
}