function downloadBlob(blobURL: string, filename: string) {
  const a = document.createElement('a');
  a.href = blobURL;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  a.remove();
}

export function downloadResource(url: string, filename: string) {
  console.log('url', url)
  return fetch(url)
  .then(response => response.blob())
  .then(blob => {
    console.log(blob)
    let blobUrl = window.URL.createObjectURL(blob);
    downloadBlob(blobUrl, filename);
  })
  .catch(e => console.error(e));
}