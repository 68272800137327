import { useMutation } from "@tanstack/react-query";
import { useBoundStore } from "../providers/boundStoreProvider";
import { bindChipWithuser } from "../services/auth";

export default function useAuth() {
  const authStore = useBoundStore((state) => state)

  const {mutateAsync, error, isPending, reset} = useMutation({
    mutationKey: ['bind-chip', authStore.token],
    mutationFn: async (chipID: string) => {
      return bindChipWithuser(authStore.token, chipID)
    }
  })

  return {
    ...authStore,
    isLoggedIn: authStore.token && authStore.phoneNumber,
    bindChip: mutateAsync,
    error, isPending, reset
  }
}