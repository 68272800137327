import { AUTH_SERVICE_API, BACKEND_API } from "../constant/endpoint";

export const getSessionStatus = async (sessionID: string, phoneNumber: string) => {
  try {
    const url = `${AUTH_SERVICE_API}/auth/status?phone_number=${phoneNumber}&session_id=${sessionID.replace("0x", "")}`
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    // myHeaders.append("Authorization", `Bearer ${token}`)

    const requestOptions: Record<string, any> = {
      method: 'GET',
      headers: myHeaders,
    };

    const rs = await fetch(url, requestOptions)
    return rs.json() 
  } catch (error) {
    console.log('getSessionStatus error', error)
    return 
  }
}

export const triggerLogin = async (sessionID: string, phoneNumber: string) => {
  try {
    const url = `${AUTH_SERVICE_API}/auth/start`
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    // myHeaders.append("Authorization", `Bearer ${token}`)
    
    const raw = JSON.stringify({
      "phone_number": phoneNumber,
      "session_id": sessionID.replace("0x", "")
    })

    const requestOptions: Record<string, any> = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    const rs = await fetch(url, requestOptions)
    return rs.json() 
  } catch (error) {
    console.log('trigger login error', error)
    return 
  }
}

export const fetchNewToken = async (refreshToken: string) => {
  try {
    const url = `${AUTH_SERVICE_API}/auth/refresh`
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${refreshToken}`)

    const requestOptions: Record<string, any> = {
      method: 'POST',
      headers: myHeaders,
      // body: raw,
      redirect: 'follow'
    };

    const rs = await fetch(url, requestOptions)
    const rsJSON = await rs.json()
    if (rsJSON.error) {
      throw new Error(rsJSON.error.error_msg || 'Unknown error')
    }

    return rsJSON
  } catch (error) {
    console.log('refresh token error', error)
    return 
  }
}

export const bindChipWithuser = async (token: string, chipID: string) => {
  try {
    const url = `${BACKEND_API}/chips/${chipID}`
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`)

    const requestOptions: Record<string, any> = {
      method: 'PUT',
      headers: myHeaders,
      // body: raw,
      redirect: 'follow'
    };

    const rs = await fetch(url, requestOptions)
    const rsJSON = await rs.json() 

    if (rsJSON.error) {
      throw new Error(rsJSON.error.error_msg || 'Unknown error')
    }
    return rsJSON
  } catch (error) {
    console.log('bind chip error', error)
    return 
  }
}

export const getPhoneChip = async (phone: string) => {
  try {
    const url = `${BACKEND_API}/chips?phone_number=${phone}&limit=1000&offset=0`

    const requestOptions: Record<string, any> = {
      method: 'GET',
    };

    const rs = await fetch(url, requestOptions)
    const rsJSON = await rs.json()
    if (rsJSON.error) {
      throw new Error(rsJSON.error.error_msg || 'Unknown error')
    }
    return rsJSON
  } catch (error) {
    console.log('getPhoneChip error', error)
    return 
  }
}