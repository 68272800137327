import { useMutation } from "@tanstack/react-query"
import { useBoundStore } from "../providers/boundStoreProvider"
import { mixSound } from "../services/music"

export const useMixSound = () => {
  const {
    soundID, setSoundID,
    quote, setQuote,
    pronoun, setPronoun,
    styleID, setStyle
  } = useBoundStore((state) => state)

  const {mutateAsync, error, isPending, reset} = useMutation({
    mutationKey: ['mix-sound', soundID, styleID],
    mutationFn: async (chipID: string) => {
      const rs = await mixSound({
        chipID,
        // quote,
        soundID,
        styleID
      })
      return rs
    }
  })

  return {
    soundID, setSoundID,
    quote, setQuote,
    pronoun, setPronoun,
    styleID, setStyle,
    mutateAsync,
    error,
    isPending,
    reset
  }
}