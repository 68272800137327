import { useQuery } from "@tanstack/react-query"
import { getAllStyle } from "../services/music"

export const useAllStyles = () => {
  const {data, isFetching, refetch} = useQuery({
    queryKey: ['all-styles'],
    queryFn: async () => {
      return getAllStyle()
    },
  })

  return {
    data,
    isFetching,
    refetch,
  }
}