import { useNavigate, useParams } from "react-router-dom";
import Header from "../../components/Header";
import Icon from "../../components/Icon";
import { Button } from "flowbite-react";
import Footer from "../../components/Footer";
import { useEffect, useState } from "react";
import { useAllStyles } from "../../hooks/useAllStyles";
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCoverflow } from "swiper/modules";

import 'swiper/css';
import 'swiper/css/effect-coverflow';
import { isSafari } from "react-device-detect";
import { useMixSound } from "../../hooks/useMixSound";

export default function SelectStyle() {
  let { itemID } = useParams();
  let navigate = useNavigate();

  const {data: allStyles} = useAllStyles()
  const {styleID, setStyle: chooseStyle} = useMixSound()
  const [style, setStyle] = useState(styleID)

  useEffect(() => {
    if (!allStyles || allStyles.length === 0) return
    setStyle(allStyles[0].id)
  }, [allStyles])

  const handleConfirm = () => {
    chooseStyle(style)
    setStyle('')
    navigate(`/item/${itemID}/mix`)
  }

  return (
    <div
      className="antialiased min-h-screen flex flex-col items-center bg-black"
    >
      <video autoPlay muted loop className="top-0 left-0 absolute" playsInline>
        <source type="video/mp4" src="/main_background.mp4" />
      </video>
      <div className="z-10 w-full flex flex-1 flex-col items-center">
        <Header />
        <div className="flex flex-col items-end justify-center w-full p-[26px]">
          <span className="text-black text-[36px] font-bold">01</span>
          <span className="uppercase text-[12px] font-bold leading-[15px]">CHỌN THỂ LOẠI NHẠC</span>
        </div>
        {/* <img className="w-[305px]" src="/asset/logo.png" /> */}
        <div className="flex-1 flex flex-col gap-[62px] items-center justify-start pt-[148px] w-full">
          <div className="gap-[12px] flex flex-col items-center w-full">
            <Swiper
              direction={'vertical'}
              effect={isSafari ? undefined : 'coverflow'}
              coverflowEffect={{
                rotate: 20,
                stretch: 0,
                depth: 100,
                modifier: 1,
                slideShadows: false,
              }}
              // spaceBetween={10}
              slidesPerView={3}
              modules={[
                EffectCoverflow,
              ]}
              className="h-[120px] w-[300px]"
              onSlideChange={(swiper) => {
                if (swiper.activeIndex <= allStyles.length - 1) {
                  console.log('slide change', swiper.activeIndex, allStyles[swiper.activeIndex].name)
                  setStyle(allStyles[swiper.activeIndex].id)
                }
              }}
            >
              {allStyles && [{name: '', id: 'empty-start'} ,...allStyles, {name: '', id: 'empty-end'}].map((styleObj: Record<string, any>) => {
                const selected = styleObj.id === style
                return (
                  <SwiperSlide
                    className={`flex items-center w-full ${selected && 'border-[1.5px] bg-[#F0F0F0]/[0.2]'} rounded-[88px] border-[#FCFCFC] justify-center font-[500] text-[20px] leading-[25px] ${selected ? 'text-[#1F1F1F]' : 'text-[#8C8C8C]'}`}
                    key={`${styleObj.id}-style`}
                  >
                    {styleObj.name}
                  </SwiperSlide>
                )
              })}
            </Swiper>
          </div>
          <Button color="dark" pill size="xs" onClick={handleConfirm}>
            <div className="gap-2 flex items-center px-[28px]">
              <span className="leading-[17.5px] text-[14px] font-[500] text-[#D9D9D9]">
                TIẾP TỤC
              </span>
              <Icon name="music-note-plus" />
            </div>
          </Button>
        </div>
        <Footer color="white" />
      </div>
    </div>
  )
}