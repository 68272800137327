import { useQuery } from "@tanstack/react-query"
import { getSoundDetail } from "../services/music"

export const useSoundDetail = (soundID: string) => {
  const {data, isFetching, refetch} = useQuery({
    queryKey: ['sound-detail', soundID],
    queryFn: async () => {
      if (!soundID || soundID === '') return undefined
      return getSoundDetail(soundID)
    }
  })

  return {
    data,
    isFetching,
    refetch
  }
}