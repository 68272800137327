export default function Footer({color, className = ''}: {
  color: 'black' | 'white';
  className?: string
}) {
  return (
    <div className={`${className} w-full flex items-center justify-between gap-2 pb-[24px] px-[16px]`}>
      <img className="h-[10px]" src={color === 'white' ? "/asset/logo_sts_white.png" : "/asset/logo_sts.png"} />
      <span className={`text-${color} italic font-bold`}>
        @All Rights Reserved
      </span>
      <img className="h-[24px]" src={color === 'white' ? "/asset/logo_white.png" : "/asset/logo.png"} />
    </div>
  )
}
