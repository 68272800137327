import { useQuery } from "@tanstack/react-query"
import { getMusicFromID } from "../services/music"

export const useItemDetail = (itemID: string) => {
  const {data, isFetching, refetch, error} = useQuery({
    queryKey: ['item-detail', itemID],
    queryFn: async () => {
      if (!itemID || itemID === '') return undefined
      return getMusicFromID(itemID)
    }
  })

  return {
    item: data,
    isFetching,
    refetch,
    error
  }
}